@import "custom-variables";
@import "bootstrap";

@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  //   overflow-x: hidden;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  color: rgb(56, 56, 56);
}

hr {
  margin: 0;
  background-color: rgb(248, 248, 248);
}

.row,
.container-fluid {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/*-- Navigation Bar --*/

.navbar {
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.15rem;
  background: rgba(0, 0, 0, 0.6) !important;
}

.navbar-brand img {
  height: 1.5rem;
}

.navbar-nav li {
  padding-right: 0.7rem;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  padding-top: 0.8rem;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active {
  border: none;
  outline: none;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link:hover {
  color: $primary;
}

#header {
  height: 100vh;
  width: 100%;
  //   background: green;
}

#header .solid {
  width: 100%;
  height: 100vh;
  background: black;
  position: absolute;
  z-index: -2;
}

#header .background {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  background-image: url(../images/bg02.jpg);
  background-size: cover;
  background-position: center center;
  opacity: 0.5;
}

#contact {
  background: rgb(16, 16, 16);
  padding-top: 2em;
  padding-bottom: 2em;
}

.section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.section::before {
  height: 1.5rem;
  content: "";
  display: block;
}

.section::after {
  height: 1.5rem;
  content: "";
  display: block;
}

.section-title {
  font-weight: 400;
  font-size: 1.3rem;
  color: $primary;
  text-transform: uppercase;
}

.tagline {
  padding-top: 1.1rem;
  font-weight: 400;
  max-width: 90%;
  margin: auto;
  //   font-size: 1.1rem;
  //   letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.75);
}

.position {
  font-size: 0.9em;
}

.field-shift {
  left: -9999px;
  position: absolute;
}

/* Form fields */

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
  appearance: none;
  transform: scale(1);
  transition: border-color 0.2s ease, background-color 0.2s ease;
  background-color: transparent;
  border-radius: 6px;
  border: none;
  border: solid 2px rgba(255, 255, 255, 0.35);
  // color: inherit;
  color: white;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  caret-color: $primary;
  width: 100%;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
  animation: focus 0.1s;
  background-color: rgba(255, 255, 255, 0.125);
  border-color: $primary;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  height: 2.75em;
}

a {
  color: rgb(152, 152, 152);
  font-weight: 400;
  transition: color 0.2s ease-in-out, color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  &:hover,
  &:focus {
    color: $primary-focused;
    border: none;
    outline: none;
    text-decoration: none;
  }

  &:active {
    color: $primary-active;
    border: none;
    outline: none;
    text-decoration: none;
  }
}

.social-team a {
  color: gray;
  transition: color 0.2s ease-in-out, color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  &:hover,
  &:focus {
    color: $primary-focused;
    border: none;
    outline: none;
    text-decoration: none;
  }

  &:active {
    color: $primary-active;
    border: none;
    outline: none;
    text-decoration: none;
  }
}

.footer-links {
  font-size: 0.8rem;
}

.footer-links li {
  margin-bottom: 1rem;
}

.copyright {
  font-size: 0.7rem;
  color: rgb(80, 80, 80);
}

.submit-button {
  appearance: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  background-color: $primary;
  border-radius: 6px;
  border: 0;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  height: 2.75em;
  line-height: 2.75em;
  padding: 0 1.125em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;

  &:hover,
  &:focus {
    background-color: $primary-focused;
    border: none;
    outline: none;
  }

  &:active {
    background-color: $primary-active;
    border: none;
    outline: none;
  }
}

input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  opacity: 0.5;
}

.form-response {
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: scale(1.2);
  height: 1rem;
  font-weight: 500;
  font-size: 0.9rem;
}

.form-response.visible {
  transform: scale(1);
  opacity: 1;
}

.form-response.success {
  color: $primary;
}

.form-response.failure {
  color: #ff2361;
}

/*============ BOOTSTRAP BREAK POINTS:

Extra small (xs) devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap

Small (sm) devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

Medium (md) devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

Large (lg) devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

Extra (xl) large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }

=============*/
